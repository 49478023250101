const questions = [
  // 1 Слайд
  {
    questionText:
      "Fuh, akhirnya dapat mengumpul semua barang-barang! Cuma saya tak boleh menceraikan almari itu sendiri.",
    answerOptions: [
      {
        answerText:
          "Saya akan tinggalkannya disini. Kehidupan baharu dengan perabot baharu.",
        answerResult:
          "Almari masih elok, sayang kalua ditinggalkan tetapi rakan-rakan mungkin sibuk. Gunakan perkhidmatan Helper. Sementara mandur bekerja, saya sempat mengasingkan barang-barang lama.",
        result: false,
      },
      {
        answerText:
          "Saya akan menghubungi rakan-rakan, mereka pasti akan membantu.",
        answerResult:
          "Almari masih elok, sayang kalua ditinggalkan tetapi rakan-rakan mungkin sibuk. Gunakan perkhidmatan Helper. Sementara mandur bekerja, saya sempat mengasingkan barang-barang lama.",
        result: false,
      },
      {
        answerText: "Tempahlah perkhidmatan Helper di aplikasi.",
        answerResult:
          "Almari masih elok, sayang kalua ditinggalkan tetapi rakan-rakan mungkin sibuk. Gunakan perkhidmatan Helper. Sementara mandur bekerja, saya sempat mengasingkan barang-barang lama.",
        result: true,
      },
    ],
    image: "images/01.webp",
  },

  // 2 слайд

  {
    questionText:
      "Ini bawa bersama dan yang ini ke dalam tong sampah. Pastinya yang satu ini saya tidak akan bawa bersama.",
    answerOptions: [
      {
        answerText:
          "Biarkan penyewa baharu akan menguruskan dengan barang lama.",
        answerResult:
          "Meminta bantuan mandur adalah pilihan yang bagus. Berikan tanda suka kepada mandur untuk kerja yang bagus di aplikasi maxim.",
        result: false,
      },
      {
        answerText:
          "Sementara mandur tidak keluar, saya akan meminta bantuan membuang barang.",
        answerResult:
          "Meminta bantuan mandur adalah pilihan yang bagus. Berikan tanda suka kepada mandur untuk kerja yang bagus di aplikasi maxim.",
        result: true,
      },
      {
        answerText: "Saya akan mengadakan jualan garaj di halaman rumah.",
        answerResult:
          "Meminta bantuan mandur adalah pilihan yang bagus. Berikan tanda suka kepada mandur untuk kerja yang bagus di aplikasi maxim.",
        result: false,
      },
    ],
    image: "images/02.webp",
  },

  // 3 слайд
  {
    questionText:
      "Tiba masanya untuk membawa barang-barang ke pangsapuri baharu! Anda rasa semuanya boleh dipindahkan sekaligus?",
    answerOptions: [
      {
        answerText: "Tidak mungkin. 3 trak masih tidak mencukupi.",
        answerResult:
          "Trak standard sudah mencukupi untuk semua barang ini. Pilihan saya ialah kadar Cargo dan sekali lagi menempah perkhidmatan Helper, kerana pastinya memerlukan bantuan untuk memuat dan memunggah.",
        result: false,
      },
      {
        answerText: "Tidak banyak barang dan muat dalam kereta biasa.",
        answerResult:
          "Trak standard sudah mencukupi untuk semua barang ini. Pilihan saya ialah kadar Cargo dan sekali lagi menempah perkhidmatan Helper, kerana pastinya memerlukan bantuan untuk memuat dan memunggah.",
        result: false,
      },
      {
        answerText: "Satu trak sudah mencukupi.",
        answerResult:
          "Trak standard sudah mencukupi untuk semua barang ini. Pilihan saya ialah kadar Cargo dan sekali lagi menempah perkhidmatan Helper, kerana pastinya memerlukan bantuan untuk memuat dan memunggah.",
        result: true,
      },
    ],
    image: "images/03.webp",
  },

  // 4 слайд
  {
    questionText:
      "Kawan berjanji yang dia akan bertemu dengan pemandu di pangsapuri baharu. Bagaimana dia boleh tahu yang kenderaan telah tiba?",
    answerOptions: [
      {
        answerText:
          "Mudah saja! Boleh mengira masa perjalanan dan menelefon kawan.",
        answerResult:
          "Anda boleh bergantung kepada naluri atau mengira masa tetapi lebih selamat menghantar pautan laluan perjalanan. Lihat pergerakan kereta membawa barang dengan mudah pada peta dalam talian.",
        result: false,
      },
      {
        answerText: "Bergantung kepada naluri penguin anda.",
        answerResult:
          "Anda boleh bergantung kepada naluri atau mengira masa tetapi lebih selamat menghantar pautan laluan perjalanan. Lihat pergerakan kereta membawa barang dengan mudah pada peta dalam talian.",
        result: false,
      },
      {
        answerText:
          "Lihat pergerakan kereta membawa barang pada peta dalam talian.",
        answerResult:
          "Anda boleh bergantung kepada naluri atau mengira masa tetapi lebih selamat menghantar pautan laluan perjalanan. Lihat pergerakan kereta membawa barang dengan mudah pada peta dalam talian.",
        result: true,
      },
    ],
    image: "images/04.webp",
  },

  // 5 слайд
  {
    questionText:
      "Saya berikan kunci kepada penyewa baharu. Masa untuk pergi ke pangsapuri baharu!",
    answerOptions: [
      {
        answerText:
          "Saya akan menempah motosikal supaya cepat sampai dan menyusun barang-barang dengan pantas.",
        answerResult:
          "Ya, berpindah bukanlah perkara mudah. Rasanya penguin kecil tidak boleh melakukannya sendiri, cepat bertemu dengannya.",
        result: true,
      },
      {
        answerText: "Bersiar-siar di sekitar bandar.",
        answerResult:
          "Ya, berpindah bukanlah perkara mudah. Rasanya penguin kecil tidak boleh melakukannya sendiri, cepat bertemu dengannya.",
        result: false,
      },
      {
        answerText: "Dan saya tidak mahu pergi.",
        answerResult:
          "Ya, berpindah bukanlah perkara mudah. Rasanya penguin kecil tidak boleh melakukannya sendiri, cepat bertemu dengannya.",
        result: false,
      },
    ],
    image: "images/05.webp",
  },

  // 6 слайд
  {
    questionText:
      "Berjaya! Pemindah telah membantu penguin kecil membawa semua barang ke arasnya. Bagaimana hendak mengucapkan terima kasih untuk kerja yang bagus.",
    answerOptions: [
      {
        answerText: "Tambah sebagai rakan di Facebook.",
        answerResult:
          "Berkomunikasi adalah bagus. Pemindah berasa lebih gembira apabila menerima tip untuk kerjanya. Jumlah ditambah kepada kos tempahan.",
        result: false,
      },
      {
        answerText: "Tinggalkan tip.",
        answerResult:
          "Berkomunikasi adalah bagus. Pemindah berasa lebih gembira apabila menerima tip untuk kerjanya. Jumlah ditambah kepada kos tempahan.",
        result: true,
      },
      {
        answerText: "Ucapkan terima kasih.",
        answerResult:
          "Berkomunikasi adalah bagus. Pemindah berasa lebih gembira apabila menerima tip untuk kerjanya. Jumlah ditambah kepada kos tempahan.",
        result: false,
      },
    ],
    image: "images/06.webp",
  },
];

export default questions;
